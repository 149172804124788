import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { ClassNames } from '@emotion/react';
import { CardMedia } from '@mui/material';



const FaqGroupItem = ({ tittle, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant='h4' align='center' fontWeight={800}>
          Facilities
      </Typography>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {tittle}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            align={'center'}
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={1000}>{item.tittle}</Typography>
            </Box>
            <AccordionDetails>
              {item.subtittle}
            </AccordionDetails>
            
            <CardMedia
                  image={item.media}
                  
                  sx={{
                    alignItems:'center',
                    align:'center',
                    width:{ xs: 70, md: 300 },

                    height: { xs: 50, md: 200 },
                    position: 'relative',
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
   
                
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  tittle: PropTypes.string,
  items: PropTypes.array.isRequired,
};

const Content = () => {

  const [config, setConfig] = useState({ base_image_url: '' });

  useEffect(() => {
    fetch('/config.json') // This resolves to /config.json in the static folder
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/about-nps/facilities/Robotics.webp`;
  const p2 = `${config.base_image_url}/home/about-nps/facilities/dance.webp`;
  const p3 = `${config.base_image_url}/home/about-nps/facilities/comLab.webp`;
  const p4 = `${config.base_image_url}/home/about-nps/facilities/lab.webp`;
  const p6 = `${config.base_image_url}/home/about-nps/facilities/sports.webp`;
  const p7 = `${config.base_image_url}/home/about-nps/facilities/math.webp`;
  const p8 = `${config.base_image_url}/home/about-nps/facilities/lib.webp`;
  const p10 = `${config.base_image_url}/home/about-nps/facilities/avroom.webp`;
  const p11 = `${config.base_image_url}/home/about-nps/facilities/medical.webp`;
  const p13 = `${config.base_image_url}/home/about-nps/facilities/sportroom.webp`;
  const p14 = `${config.base_image_url}/home/about-nps/facilities/MUSICROOM.webp`;
  const p15 = `${config.base_image_url}/home/about-nps/facilities/audi.webp`;
  const p16 = `${config.base_image_url}/home/about-nps/facilities/conf.webp`;
  const p17 = `${config.base_image_url}/home/about-nps/facilities/ARTROOM.webp`;
  const p19 = `${config.base_image_url}/home/about-nps/facilities/cctv.webp`;
  const p20 = `${config.base_image_url}/home/about-nps/facilities/physics.webp`;
  const p21 = `${config.base_image_url}/home/about-nps/facilities/Chemistry.webp`;
  const p22 = `${config.base_image_url}/home/about-nps/facilities/Activity.webp`;
  const p23 = `${config.base_image_url}/home/about-nps/facilities/yoga.webp`;
  const p24 = `${config.base_image_url}/home/about-nps/facilities/science.webp`;
  const p25 = `${config.base_image_url}/home/about-nps/facilities/assemblyarea.webp`;
  const p26 = `${config.base_image_url}/home/about-nps/facilities/drinkingwater.webp`;
  const p27 = `${config.base_image_url}/home/about-nps/facilities/indoorsports.webp`;
  const p28 = `${config.base_image_url}/home/about-nps/facilities/outdoor.webp`;
  const p29 = `${config.base_image_url}/home/about-nps/facilities/pre_primary.webp`;
  const p30 = `${config.base_image_url}/home/about-nps/facilities/selected.webp`;
  const p31 = `${config.base_image_url}/home/about-nps/facilities/rep.webp`;

  return (
    <Box>
      <Box marginBottom={1}>
        <FaqGroupItem
          items={[
            {
              media:p30,
              tittle: 'Class Room',
              subtittle:
           
                  <table>
                    <thead >
                    <tr>
                      <>
                        <td>The classrooms are large and well ventilated with white/green boards and bulletin boards inside and outside the classrooms. Strength of each class is kept at manageable level with comfortable furniture which adds to the ambience of the class room.</td>
                      </>
                    </tr>
                    </thead>
                  </table>
                  
            },
            {
              tittle: 'Computer Lab',
              media:p3,
              subtittle:
              <table>
                <thead>
               
                  <tr>
                    <td> Computer lab with internet connection to source the websites for educational material. Computer classes start from Grade 1 onwards. Internet can be accessed by students in presence of a teacher only.</td>
                  </tr>

                </thead>
              
              </table>
            },
            {
              tittle:'Science Lab',
              media:p24,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>National Public School Yeshwanthpur believes that effective teaching and learning of science involves seeing,
handling, and manipulating real objects and materials.<br/>
Science is different from any other subject. In order to cultivate practical understanding of its concepts, one
has to look beyond the books and conventional classroom teaching. The knowledge that students attain in
classrooms would be effective when they actually observe the process and understand the relationship
between various physical valuables.<br/>
The Science lab equipment exclusively set for Physics, Chemistry and Biology allows students to interact
directly with the data gathered. They get a first-hand learning experience by performing various experiments
on their own.</td>
                  </>
                </tr>
                </thead>
              </table>
            },
            {
              tittle: 'Physics Lab',
              media:p20,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    {/* <td> The school is equipped with Math, Science - Physics, Chemistry & Biology and Computer labs. The labs are well-stocked with the required equipments, chemicals and aids that assist in participative and practical learning of the students. All the labs are equipped with multimedia to provide online support to students and teachers.</td> */}
                  </>
                </tr>
                </thead>
              </table>

            },
            {
              tittle: 'Chemistry Lab',
              media:p21,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    {/* <td> The school is equipped with Math, Science - Physics, Chemistry & Biology and Computer labs. The labs are well-stocked with the required equipments, chemicals and aids that assist in participative and practical learning of the students. All the labs are equipped with multimedia to provide online support to students and teachers.</td> */}
                  </>
                </tr>
                </thead>
              </table>

            },

            {
              tittle: 'Biology Lab',
              media:p4,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    {/* <td> The school is equipped with Math, Science - Physics, Chemistry & Biology and Computer labs. The labs are well-stocked with the required equipments, chemicals and aids that assist in participative and practical learning of the students. All the labs are equipped with multimedia to provide online support to students and teachers.</td> */}
                  </>
                </tr>
                </thead>
              </table>

            },

            {
              tittle: 'Maths Lab',
              media:p7,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>A well equipped maths lab provides the students with the opportunity to have fun with numbers. It helps the students to put their mathematical abilities to test and enhance their analytical reasoning and lateral thinking through various models, games and activities.</td>
                  </>
                </tr>
                </thead>
              </table>
            },
            
            {
              tittle: 'Library',
              media:p8,
              subtittle:
              <table>
                <thead>
                <>
              
                  {/* <br></br> */}
                  <tr>
                    <td>Library is the main resource of centre in NPS, Yeshwanthpur. It has a seating capacity of 200 students. It mainly contains text books, reference books, fiction titles, periodicals, audio visual material like CD and VCD’s and teaching resources. There is a special reading programme which encourages the children to read the recommended library books.</td>
                  </tr>
                 
                </>
                </thead>
            
              </table>
            },
            {
              tittle: 'AV Rooms',
              media:p10,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>Well-furnished and spacious classrooms are a very appealing feature of our school infrastructure. The green boards and bulletin boards encourage students to decorate their classrooms and display their creative ideas and artistic work.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
           
            {
              tittle: 'Music Room',
              media:p14,
              subtittle:
              <table>
                <thead>
                <>
                 
                  {/* <br></br> */}
                  <tr>
                    <td>The well-equipped Music room contains a variety of Classical and Western musical instruments. Children are trained in both the disciplines of instrumental and vocal music.</td>
                  </tr>
                 
                </>
                </thead>
              </table>
            },
            {
              tittle: 'Art / Craft Room',
              media:p17,
              subtittle: 
              <table>
                <thead>
                <tr>
                  <>
                    <td>Artistic pursuits produce thinking patterns and abilities that transfer to other areas of life. Art is given utmost importance in the curriculum. Ample opportunities are provided to the students to give expression to their imagination and thus enhance creativity. The art room drives each child to be curious and original in their work while they study a variety of Art forms.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
            {
              tittle: 'Playground',
              media:p6,
              subtittle:
              <table>
                <thead>
                
                  <tr>
                    <td>To ensure the holistic development of the students- games and sports form an integral part of the school curriculum. It has a play area and sand pit area for the younger kids to play and explore. There is a football ground, a running trail, a basketball court and multi-purpose courts for students to hone their skills.</td>
                  </tr>
                  
                 
                
                </thead>
              </table>
            },
            {
              tittle: 'Sports Room',
              media:p13,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>Sports Room: It is a multipurpose hall equipped with indoor games badminton, chess and table tennis.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
            {
              tittle: 'Robotics Room',
              media:p1,
              media1:p2,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>Robotics Room: The school has a specially designed Activity Room which is well equipped with educational toys and games that provide a stimulating environment for spontaneous learning. Children engage in various individual and group activities that facilitate hands- on learning. Real life experiences spanning the world beyond classroom are provided.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
            {
              tittle: 'Dance Room',
              media:p2,
              subtittle:
              
              <table>
                <thead>
                <tr>
                  <>
                    <td>Dance Room: A separate and spacious Dance Studio that is well equipped with necessary technical instruments, is a place where children are trained in various dance forms.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
            // {
            //   tittle: 'How should the notebooks and textbooks be covered?',
            //   subtittle:
            //       <table>
            //         <thead>
            //         <tr>
            //           <>
            //             <th>For Classes - Montessori II, Montessori III, KG I, KG II, Classes 1 and 2:</th>
            //           </>
            //         </tr>
            //         {/* <br></br> */}

            //         <tr>
            //           <td>✓ Notebooks need not be covered.</td>
            //         </tr>
            //         <tr>
            //           <td>✓ Textbooks must be covered with plain brown sheet.</td>
            //         </tr>
            //         <tr>
            //           <td>✓ Stick labels on the top right hand corner of the textbook.</td>
            //         </tr>

            //         <tr>
            //           <td>✓ Mention the name of the child and class on the label. The section and the subject will be entered later.</td>
            //         </tr>
            //         <tr>
            //           <td>✓ Refrain from using fancy labels.</td>
            //         </tr>
            //         {/* <br></br> */}
            //         <tr>
            //           <>
            //             <th>For Classes - 3 to 12:</th>
            //           </>
            //         </tr>
            //         {/* <br></br> */}
            //         <tr>
            //           <>
            //             <td>✓ Notebooks need not to be covered.</td>
            //           </>
            //         </tr>
            //         <tr>
            //           <>
            //             <td>✓ Textbooks must be covered with plain brown sheet.</td>
            //           </>
            //         </tr>
            //         </thead>
            //       </table>
            // },
            {
              tittle: 'Health Room',
              media: p11,
              subtittle:
              
              <table>
                <thead>
                <>
                  
                 
                  <tr>
                    <td>It is a two bedded medical room fully equipped to cater to any unforeseen emergency. Students who require medical attention during school hours are taken care with permission of the teacher in the class or the Coordinator. Doctors are available on call to attend to any exigencies.</td>
                  </tr>
                 
                </>
                </thead>
              </table>
            },
            {
              tittle: 'Portable Water',
              media:p26,
              subtittle:
              <table>
                <thead>
                <tr>
                  <>
                    <td>The school ensures uninterrupted supply of clean drinking water i.e; RO filtered water on all the floors.</td>
                  </>
                </tr>
                </thead>
              </table>

            },
            {
              tittle: 'Reception',
              media:p31 ,

            },
            {
              tittle: 'CCTV',
              media:p19,
              subtittle:
                  <table>
                    <thead>
                    <tr>
                      <>
                        <td>The school is under CCTV surveillance so as to ensure safety and security. It not only helps in monitoring but also to keep a track of activities taking place within the school premises. There are a total of 53 cameras installed in various parts of the school premises.</td>
                      </>
                    </tr>
                    {/* <br></br> */}
                    </thead>
                  </table>
            },
            {
              tittle: 'Auditorium',
              media:p15 ,

            },
            {
              tittle: 'Conference Room',
              media:p16 ,

            },
            {
              tittle: "Activity Room",
              media:p22 ,
            },
            {
              tittle: "Yoga Room",
              media:p23 ,
            },
            {
              tittle: "Assembly Area",
              media:p25 ,
            },
            // {
            //   tittle: "Drinking Water",
            //   media:p26 ,
            // },
            {
              tittle: "Indoor Sports Room",
              media:p27 ,
            },
            {
              tittle: "Outdoor Games",
              media:p28 ,
            },
            {
              tittle: "Pre Primary Activity Room",
              media:p29 ,
            },
            // {
            //   tittle: "Reception Area",
            //   media:p31,
             
            // },
            {
              tittle: 'The Additional facilities are :',
              subtittle:
              <table>
                <thead >
                <>
                
                  {/* <br></br> */}
                
                 
                  {/* <br></br> */}
                 
                  {/* <br></br> */}
                  
                  {/* <br></br> */}
                
                  {/* <br></br> */}
                  
                  <tr>
                    <td>Counselling Centre</td>
                  </tr>
                  
                 
                  <tr>
                    <td> Resource Room</td>
                  </tr>
                </>
                </thead>
              </table>
            },
           
            
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;